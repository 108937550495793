<template>
  <v-container fluid fill-height class="primary u-fixed">
    <v-row justify="center">
      <v-col cols="12" sm="4">
        <v-img class="c-img-logo" src="@/assets/AVIZVFX.png"></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AvizVfx",
};
</script>
<style>

</style>
