<template>
  <v-app id="inspire">
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    load: false,
    sessaoExpirada: false,
    userLogado: false,
    dadosUser: {},
    valBoletoFooter: {},
  }),
  methods: {},
};
</script>
<style>
.u-flex {
    display: flex;
    justify-content: end;
}
.u-obs {
    text-align: right;
    background: rgb(255 255 255 / 8%);
    padding: 5px;
    border-radius: 4px;
    font-size: 11px;
    letter-spacing: 0px;
    line-height: 13px;
    width: 295px;
}

.c-box {
    border: solid 1px #02ff00;
    border-radius: 5px 1px;
    padding: 9px;
    padding-top: 15px;
}
.c-box .u-h4{
  padding-left: 7px;
}
.c-box-desc {
    padding: 10px;
    background: #161616;
    border-radius: 5px 1px;
    margin-top: 12px;
    padding-left: 20px;
}
.u-h4 {
  font-family: 'Azeret Mono', monospace;
  font-size: 1rem;
  font-weight: 500;
  color: #858585;
}
.u-h5 {
  font-family: 'Azeret Mono', monospace;
  font-size: 0.8rem;
  font-weight: 500;
  color: #02ff00;
  letter-spacing: 1px;
  margin-top: 15px;
}
.u-descricao {
    font-family: 'Azeret Mono', monospace;
    font-size: 0.7rem;
    font-weight: 200;
    color: #02ff00;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding-top: 10px 
}
.u-h3 {
  font-family: 'Azeret Mono', monospace;
  font-size: 1.2rem;
  font-weight: 400;
  color: #858585;
}
.u-fixed {
  position: fixed;
}
.u-hide {
  display: none;
}
.u-not_brack {
  word-break: break-word;
}
.u-fixed {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.c-load {
  min-width: 100vw;
  position: absolute;
  z-index: 9999;
}
.u-relative {
  position: relative;
}
.u-ponte {
    cursor: pointer;
    transition: ease 1s
}
.u-ponte:hover {
    transition: ease 1s;
    opacity: 0.5;
}
/* .c-val-boleto__footer .v-input__slot, .c-val-boleto__footer .v-input__control {
    background: #235e44!important;
    color: white;
}
.c-val-boleto__footer * {
    padding: 0px!important;
    margin: 0px!important;
    color: white!important;
}
.c-val-boleto__footer {
    display: inline-flex!important;
    width: 16%;
    padding-left: 8px;
    text-align: center;
} */
</style>