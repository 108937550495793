<template>
  <v-row>
    <v-col v-for="n in 27" :key="n" class="d-flex child-flex" cols="4">
      <v-img
        :src="`files/${n}.webp`"
        :lazy-src="`files/draf/${n}.webp`"
        min-width="100%"
        class="secondary u-ponte"
        @click="zooImg(n)"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-col>
    <v-col><v-chip large class="d-none d-md-flex text-subtitle-1 font-weight-medium" color="secondary">Para ver mais trabalhos e os arquivos em alta resolução acesse: <v-btn class="ml-1" rounded href="https://avizvfx.com.br/FilesAviz" target="_blank" ><v-icon left>mdi-link-variant </v-icon>avizvfx.com.br/Files-Aviz</v-btn></v-chip>
    
    <div class="d-flex d-sm-none text-subtitle-1 font-weight-medium d-flex flex-column">
    Para ver mais trabalhos e os arquivos em alta resolução acesse: <v-btn class="mx-1 my-3" rounded href="https://avizvfx.com.br/FilesAviz" target="_blank" ><v-icon left>mdi-link-variant </v-icon>avizvfx.com.br/Files-Aviz</v-btn></div>
    </v-col>
    <v-dialog v-model="zooImgDialog" max-width="500" hide-overlay>
      <v-progress-circular
        class="c-btn-load__dialog"
        v-show="loadZoom"
        :indeterminate="loadZoom"
        color="secondary"
      ></v-progress-circular>
      <v-card flat color="transparent" class="c-img-zoom-">
        <v-img
          :key="draftImgClicada"
          content-class="c-box-img__zoom"
          :src="imgClicada"
          :lazy-src="draftImgClicada"
          contain
          min-width="100%"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
        <!-- <v-btn
          @click="zooImgDialog = false"
          absolute
          top
          fab
          small
          right
          :color="bgCorIcon"
          class="mt-7 mr-n3"
        >
          <v-icon :color="corIcon">mdi-close-circle-outline</v-icon>
        </v-btn> -->
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "GaleriaAvizVFX",
  data() {
    return {
      zooImgDialog: false,
      imgClicada: "",
      draftImgClicada: "",
      loadZoom: false,
      corIcon: "",
      bgCorIcon: "",
    };
  },
  methods: {
    zooImg(id) {
      console.log('window.location.hostname', window.location.hostname)
      this.loadZoom = true;
      this.zooImgDialog = true;
      this.imgClicada = `files/Alta/${id}.jpg`;
      this.draftImgClicada = `files/Alta/draft/${id}.jpg`;
      this.loadZoom = false;
    },
    erroAoCarregarImagem() {
      console.log("Opa Não carregou! Algo aconteceu!");
    },
    exbirProgresso(e) {
      console.log(e);
      /* let reader = new FileReader();
      let file = await fetch(e).then((r) => r.blob());

      reader.readAsDataURL(file);
      reader.onloadend = () => {
        console.log("img em base", reader.result);
        this.getAverageRGB(reader.result);
      }; */
    },
    removerProgresso(event) {
      console.log("opa terminou de carregar!", event);
      this.corMaisComum(event);
    },
    inverterCor(bg) {
      bg = parseInt(Number(bg.replace("#", "0x")), 10);
      bg = ~bg;
      bg = bg >>> 0;
      bg = bg & 0x00ffffff;
      bg = "#" + bg.toString(16).padStart(6, "0");

      return bg;
    },
    corMaisComum(e) {
      function arredonda(v) {
        return 5 * Math.round(v / 5);
      }

      function componentToHex(c) {
        var hex = c.toString(16);
        return hex.length == 1 ? "0" + hex : hex;
      }

      function rgbToHex(r, g, b) {
        return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
      }

      var img = new Image();
      img.src = e;

      //cria um canvas invisível
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var context = canvas.getContext("2d");

      //desenha a imagem no canvas
      context.drawImage(img, 0, 0);

      //recupera vetor de cores
      var map = context.getImageData(img.width - 50, 10, 10, 10).data;
      //monta histograma
      var hex, r, g, b; //,alpha;
      var histograma = {};
      for (var i = 0, len = map.length; i < len; i += 4) {
        //recupera componentes de um ponto
        r = arredonda(map[i]);
        g = arredonda(map[i + 1]);
        b = arredonda(map[i + 2]);
        //alpha = map[i+2]; //ignora canal alpha

        //valor em hexadecimal
        hex = rgbToHex(r, g, b);

        //adiciona no histograma ou incrementa se já existir
        if (histograma[hex] === undefined) {
          histograma[hex] = 1;
        } else {
          histograma[hex]++;
        }
      }

      //recupera cor mais comum
      var corMaisComum = null;
      var frequenciaCorMaisComum = 0;
      for (var cor in histograma) {
        if (frequenciaCorMaisComum < histograma[cor]) {
          corMaisComum = cor;
          frequenciaCorMaisComum = histograma[cor];
        }
      }

      this.corIcon = this.inverterCor(corMaisComum);
      this.bgCorIcon = corMaisComum;

      console.log("corMaisComum", corMaisComum);
      console.log("corInvertida", this.inverterCor(corMaisComum));
    },
  },
};
</script>

<style>
.c-img-zoom {
  position: relative;
}
.c-btn-load__dialog {
  left: calc(50%);
  position: fixed;
  top: 10%;
  z-index: 9999999;
  margin-top: 50px;
}
</style>