import Vue from 'vue'
import VueRouter from 'vue-router'
import AvizVfx from '../views/AvizVfx.vue'
import Contratos from '@/components/Contratos.vue'
import Curriculo from '@/components/Curriculo.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'AvizVfx',
    component: AvizVfx
  },
  {
    path: '/Contratos',
    name: 'contratos',
    component: Contratos
  },
  {
    path: '/Curriculo',
    name: 'curriculo',
    component: Curriculo
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
