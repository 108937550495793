<template>
  <embed src="files/aviz/Washington_Aviz.pdf" type="application/pdf" width="100%" height="100%" />
</template>

<script>
export default {
name: 'CurriculoAviz'
}
</script>

<style>

</style>